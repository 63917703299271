@use '@angular/material' as mat;
@include mat.core();

$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$pink-palette),
    accent: mat.define-palette(mat.$blue-grey-palette),
  ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$indigo-palette),
    accent: mat.define-palette(mat.$pink-palette),
  ),
));

// Apply the light theme by default
@include mat.core-theme($light-theme);
@include mat.button-theme($light-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same typography and density styles.
  @include mat.core-color($dark-theme);
  @include mat.button-color($dark-theme);
}
